body {
    font-family: 'Lucida Sans Unicode';
  }
  
  
  h1 {
    text-align: center;
    font-size: 50px;
  }
  

  .newGameButton {
    background-color: #303030;
    color: #50C8FF;
    height: 30px;
    width: 100px;
    border-radius: 5px;
    margin: 0 auto;
    margin-bottom: 25px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s, color 0.5s;
  }
  .shuffleButton {
    background-color: #303030;
    color: #ff9050;
    height: 30px;
    width: 100px;
    border-radius: 50px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s, color 0.5s;
  }
  
  .button:hover {
    background-color: #50C8FF;
    color: #303030;
    transition: background-color 0.5s, color 0.5s;
  }
  
  .gameBoard {
     /* margin: 0 10px 10px 0;  */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cell {
    background: #fff;
    border: 1px solid #999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    font-size: 24px;
    font-weight: bold;
    line-height: normal;

    margin-right: -1px;
    margin-top: -1px;
    height: 90px;
    width: 90px;  
    cursor: pointer;
}

@media all and (max-width:720px) {
  .cell{
    height: 60px;
    width: 60px; 
  }
}

@media all and (max-height:800px) {
  .cell{
    height: 60px;
    width: 60px; 
  }
}



@media all and (max-width:420px) {
  .cell{
    height: 45px;
    width: 45px; 
  }
}

@media all and (max-height:730px) {
  .cell{
    height: 45px;
    width: 45px; 
  }
}



@media all and (max-width:340px) {
  .cell{
    height: 30px;
    width: 30px; 
  }
}

@media all and (max-height:500px) {
  .cell{
    height: 30px;
    width: 30px; 
  }
}

.square {
    border: 0px;
    height: 90%;
    width: 90%;  
    margin: auto;
}

  .message {
    text-align: center;
    font-size: 17px;
  }
  
  @keyframes shinyRedColors {
    from { background-color: rgb(255, 116, 116);}
    50% { background-color: rgb(248, 39, 39); }
    90% { background-color: rgb(255, 1, 1); }
    100% {background-color: rgb(255, 135, 88); }
  }

  @keyframes shinyYellowColors {
    from { background-color: rgb(250, 250, 119);   }
    50% { background-color: rgb(252, 223, 62)  }
    90% { background-color: rgb(255, 213, 73);  }
    100% {background-color: rgb(255, 242, 64);  }
  }
  .red-background{
      background-color: rgb(255, 116, 116);
      transition: background-color 0.25s;
  }
  .yellow-background {
    background-color: rgb(255, 255, 145);
    transition: background-color 0.25s;

}
.shiny-red {
  background-color: rgb(255, 116, 116);
  transition: background-color 0.25s;
  animation: shinyRedColors 3s;
  animation-iteration-count: infinite;
}

.shiny-yellow {
  background-color: rgb(255, 255, 145);
  transition: background-color 0.25s;
  animation: shinyYellowColors 3s;
  animation-iteration-count: infinite;
}