
.controlPanel {
    color: #000000;
    border-radius: 100%;
    margin: 0 auto;
    margin-top: 5px;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s, color 0.5s;
  }

  @media all and (max-width:600px) {
    .controlPanel{
      padding: 0;
    }
    .aside-1{
      padding:10px 0 10px;     
    }
    .aside-2 {
      order:1;
      margin-bottom:8px;
    }
    .middlePanel {order:2}
    .aside-3{order:3}
  }

.background-green{
  background-color: #16be7b;
  color: black;
}

.background-pink{
  background-color: rgb(231, 95, 213);
  color: black;
}

.background-gray{
    color: white;
    background-color: #6a726f;
  }

.controlPanelButton {
  text-decoration: none;
  font-family: Helvetica, Arial, Sans-Serif;
  padding: 10px;
  border: none;
  transition: all 0.4s ease 0s;
  font: Arial
  }

.controlPanelButton:focus{
  outline: 0;
}
.controlPanelButton:hover {
  outline: 0;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  transition: all 0.4s ease 0s;
  }

.dropdown-bootrap{
  height:44px;
  font-family: Helvetica, Arial, Sans-Serif;
  width: 150px;
}

input[type=text] {
  padding: 9px;
  border: 0px;
  width: 220px;
}