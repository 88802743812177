:root {
  --gray-white: #f4f4f2;
  --gray-one: #e8e8e8;
  --gray-two: #bbbfca;
  --gray-three: #495464;
  --cool-blue: #8fb0e9;
  --cool-blue-two: #5e8ddd;
}

h2{
  color: black;
}

.container-app{
  background-color: var(--gray-two);
}

.App {
  text-align: center;
  background-color: var(--cool-blue);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: var(--gray-one);
}
